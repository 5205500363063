import request from "@/utils/request";

//客户列表统计
export function customerMaintainList(data) {
  return request({
    url: "api/v2/maintain/customerMaintainList",
    method: "post",
    data,
  });
}
//使用情况统计
export function statisticsCustomerList(data) {
  return request({
    url: "api/v2/maintain/statisticsCustomerList",
    method: "post",
    data,
  });
}

//客户列表
export function statisticsOrgList(data) {
  return request({
    url: "api/v2/maintain/statisticsOrgList",
    method: "post",
    data,
  });
}

//使用情况
export function statisticsList(data) {
  return request({
    url: "api/v2/maintain/statisticsList",
    method: "post",
    data,
  });
}

//新增回复
export function createAutoRobot(data) {
  return request({
    url: "api/v2/maintain/createAutoRobot",
    method: "post",
    data,
  });
}

//回复list
export function autoRobotList(data) {
  return request({
    url: "api/v2/maintain/autoRobotList",
    method: "post",
    data,
  });
}

//删除回复
export function delAutoRobot(data) {
  return request({
    url: "api/v2/maintain/delAutoRobot",
    method: "post",
    data,
  });
}

//批量修改销售经理
export function updateSaleName(data) {
  return request({
    url: "api/v2/maintain/updateSaleName",
    method: "post",
    data,
  });
}

//刷新list
export function refreshStatisticsCache(data) {
  return request({
    url: "api/v2/maintain/refreshStatisticsCache",
    method: "post",
    data,
  });
}

//综合评分列表
export function comprehensiveList(data) {
  return request({
    url: "api/v2/maintain/comprehensiveList",
    method: "post",
    data,
  });
}

//修改综合评分列表
export function updateComprehensive(data) {
  return request({
    url: "api/v2/maintain/updateComprehensive",
    method: "post",
    data,
  });
}

//刷新综合评分列表
export function refreshComprehensive(data) {
  return request({
    url: "api/v2/maintain/refreshComprehensive",
    method: "post",
    data,
  });
}

//进度跟踪列表
export function userOrgList(data) {
  return request({
    url: "api/v2/maintain/userOrgList",
    method: "post",
    data,
  });
}

//进度跟踪导出
export function exportUserOrgList(data) {
  return request({
    url: "api/v2/maintain/exportUserOrgList",
    method: "post",
    data,
  });
}


//任务进度list
export function statList(data) {
  return request({
    url: "api/v2/maintain/statList",
    method: "post",
    data,
  });
}

//任务数据更新
export function statDep(data) {
  return request({
    url: "api/v2/maintain/statDep",
    method: "post",
    data,
  });
}

//设置取数部门
export function statDepFetch(data) {
  return request({
    url: "api/v2/maintain/statDepFetch",
    method: "post",
    data,
  });
}

//税种list
export function taxStatList(data) {
  return request({
    url: "api/v2/maintain/taxStatList",
    method: "post",
    data,
  });
}

//统计-任务导出
export function statExport(data) {
  return request({
    url: "api/v2/maintain/statList/export",
    method: "post",
    data,
  });
}

//统计-报税任务导出
export function taxStatExport(data) {
  return request({
    url: "api/v2/maintain/taxStatList/export",
    method: "post",
    data,
  });
}