<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="文件上传"
    width="30%"
    destroy-on-close
  >
    <FileUploader @change="changeFileList" />
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveFile">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import FileUploader from '@/components/uploadImg/FileUploader'

import { uploadPaymentRecordFile } from '@/api/newCrm'

const emit = defineEmits(['success'])

const dialogFormVisible = ref(false)
const fileList = ref([])
const innerRow = ref()

function saveFile() {
  uploadPaymentRecordFile({
    file: [...fileList.value.map(item => item.url), ...innerRow.value.file.split(',')],
    paymentId: innerRow.value.id
  }).then(res => {
    if (res.data.msg == "success") {
      ElMessage.success('上传成功')
      emit('success')
      dialogFormVisible.value = false
    }
  })
}
function show(row) {
  dialogFormVisible.value = true
  innerRow.value = row
}

function changeFileList(l) {
  fileList.value = l
}

defineExpose({
  show,
})
</script>

<style lang="less">
</style>
