<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入客户编码/客户名称" v-model.trim="listQuery.customerName" style="width: 200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right: 10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 550px" label-width="90px" size="small" inline="true" class="style_form">
            <el-form-item label="收款日期：">
              <el-date-picker style="width: 150px" v-model="listQuery.beginTime" type="date" placeholder="起始日期"
                clearable value-format="YYYY-MM-DD" />
              &nbsp;-&nbsp;
              <el-date-picker style="width: 150px" v-model="listQuery.endTime" type="date" placeholder="终止日期" clearable
                value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="经办人：">
              <select-common placeholder="请选择经办人" v-model:modelValue="listQuery.accountingId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核人：">
              <select-common placeholder="请选择审核人" v-model:modelValue="listQuery.auditUserId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核状态：">
              <select-common placeholder="请选择审核状态" v-model:modelValue="listQuery.auditStatus"
                :options="statusOptions" />
            </el-form-item>
            <el-form-item label="单据编码：">
              <el-input v-model="listQuery.documentCode" placeholder="请输入单据编码" clearable></el-input>
            </el-form-item>
            <el-form-item label="结算方式：">
              <select-common placeholder="请选择结算方式" v-model:modelValue="listQuery.settlementModeId"
                :options="paymentOptions" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Plus" @click="createNew">新增</el-button>
        <el-button size="small" type="danger" plain icon="Delete" @click="patchDel">删除</el-button>
        <!-- <el-button size="small" type="primary" icon="Position" @click="submitApprove"
          >提交审核</el-button
        > -->
        <el-button size="small" type="primary" icon="Folder" @click="exportExcel">导出</el-button>
        <!-- <el-button size="small" type="success" icon="Printer">打印</el-button> -->
        <el-button size="small" type="primary" icon="Position" @click="revokeApprove">撤销提交</el-button>
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table v-loading="loading" :data="list" :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }" height="auto" style="width: 100%"
        border stripe auto-resize size="mini" @checkbox-change="handleSelectionChange"
        @checkbox-all="handleSelectionChange" :column-config="{ resizable: true }">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50">
        </vxe-column>
        <vxe-column field="paymentDate" title="收款日期" width="90"> </vxe-column>
        <vxe-column field="documentCode" title="单据编码" minWidth="90">
          <template #default="scope">
            <span class="clickable_style" @click="chargeDetail(scope.row)">{{
              scope.row.documentCode
            }}</span>
          </template> </vxe-column>
        <vxe-column field="createdAt" title="操作日期" width="90">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="90">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称" minWidth="90"> </vxe-column>
        <vxe-column field="receivedAmount" title="金额" minWidth="90"> </vxe-column>
        <!-- <vxe-column field="paymentDiscount" title="本次优惠"> </vxe-column> -->
        <vxe-column field="accountingName" title="经办人" minWidth="90"> </vxe-column>
        <vxe-column field="cnName" title="录入人" minWidth="90"> </vxe-column>
        <vxe-column field="auditUser" title="审核人" minWidth="90"> </vxe-column>
        <vxe-column field="auditStatus" title="审核状态" minWidth="90">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.auditStatus) }}</span>
          </template>
        </vxe-column>
        <!-- <vxe-column field="inventoryName" title="审核批语"> </vxe-column> -->
        <vxe-column field="paymentExplain" title="收款说明" minWidth="130"> </vxe-column>
        <vxe-column field="opera" title="操作" width="240">
          <template #default="{ row }">
            <el-button type="primary" link @click="() => showUpload(row)">上传</el-button>
            <el-button type="primary" link @click="() => showFileList(row)">查看</el-button>
            <el-button type="primary" link @click="() => approveHistoryShow(row)">审核历史</el-button>
            <el-button type="primary" link @click="() => submitApprove(row)"
              :disabled="row.auditStatus !== 1">提交审核</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <div class="page_container">
      <qzf-pagination :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>

    <CheckRecord ref="checkRecordRef" :type="3" />
    <UploadFile ref="uploadFileRef" @success="getList" />
    <ViewFileList ref="viewFileListRef" />

    <SubmitCheck ref="submitCheckRef" :type="3" @success="getList" />

    <ChargeForm ref="chargeFormRef" :userOptions="userOptions" @success="getList" />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { paymentList, deletePayment, paymentMethodList, exportPayment, revokePaymentRecord } from "@/api/newCrm";
import { findPeople } from "@/api/base";
import {
  CONTRACT_STATUS,
} from "@/utils/commonData";
import { ElMessageBox, ElMessage } from "element-plus";
import CheckRecord from "@/views/collectionSystem/contract/components/checkRecord.vue";
import UploadFile from './components/UploadFile.vue'
import ViewFileList from './components/ViewFileList.vue'
import SubmitCheck from "@/views/collectionSystem/contract/components/submitCheck.vue"
import ChargeForm from "./components/chargeForm.vue";

const { proxy } = getCurrentInstance();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
});
const statusOptions = ref([
  ...[{ label: "全部", value: 0 }],
  ...CONTRACT_STATUS,
]);
const userOptions = ref([])
const ids = ref([])
const selectRows = ref([])
const paymentOptions = ref()
const checkRecordRef = ref();
const uploadFileRef = ref()
const total = ref(0)
const viewFileListRef = ref()
const submitCheckRef = ref()
const chargeFormRef = ref()

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list.map(item => {
      return {
        ...item,
        label: item.CnName,
        value: item.id,
      }
    });
  });
  paymentMethodList().then((res) => {
    paymentOptions.value = res.data?.data?.list?.map(item => {
      return {
        label: item.methodName,
        value: item.id
      }
    });
  });
  getList();
});

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
  }
  getList()
}
const getList = () => {
  paymentList({
    ...listQuery.value,
    auditUserId: listQuery.value.auditUserId || undefined,
    accountingId: listQuery.value.accountingId || undefined,
    auditStatus: listQuery.value.auditStatus || undefined,
    settlementModeId: listQuery.value.settlementModeId || undefined,
  }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total
    }
  });
};

function createNew() {
  chargeFormRef.value.init()
}
const chargeDetail = (row) => {
  chargeFormRef.value.init(row)
}
const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};

function patchDel() {
  if (ids.value.length == 0) {
    ElMessage.warning('请至少选择一条数据');
    return
  }
  ElMessageBox.confirm('确定删除?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deletePayment({ ids: ids.value }).then(res => {
      if (res.data.msg == 'success') {
        ElMessage.success("删除成功")
        getList()
      }
    })
  })
}

function submitApprove(item) {
  submitCheckRef.value.init(item)
}

function revokeApprove() {
  if (ids.value.length == 0) {
    ElMessage.warning('请至少选择一条数据');
    return
  }
  const not5 = selectRows.value.filter(item => item.auditStatus !== 5)
  if (not5.length) {
    ElMessage.warning('请选择审核状态为“审核中”的数据')
    return
  }
  ElMessageBox.confirm('确定撤销审核?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    revokePaymentRecord({ ids: ids.value }).then(res => {
      if (res.data.msg == 'success') {
        ElMessage.success("撤销成功")
        getList()
      }
    })
  })
}

function handleSelectionChange({ records: rows }) {
  selectRows.value = rows
  ids.value = rows.map(item => item.id)
}

function approveHistoryShow(row) {
  checkRecordRef.value.init(row)
}
function showUpload(row) {
  uploadFileRef.value.show(row)
}
function showFileList(row) {
  viewFileListRef.value.show(row)
}

function exportExcel() {
  exportPayment({ids: ids.value, query: {...listQuery.value}})
    .then(res => {
      if (res.data.msg == "success") {
        window.open(res.data.data.url, '_blank');
      }
    })
}
</script>
<script>
export default {
  name: "chargeSign",
};
</script>
<style scoped lang="scss">
.page_container {
  float: right;
  margin-top: 10px;
}

.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(.el-checkbox) {
  margin-right: 15px;
}

.style_form {
  .el-input {
    width: 147px;
  }

  .el-select {
    width: 147px;
    margin: 0;
  }
}

.filter-item {
  margin-right: 15px !important;
}

:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
